import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('reports/insurance_life', {
            params: queryParams
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadreports(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('reports/insurance_life', {
                params: queryParams
              }, {
            responseType: 'blob'
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
